import styled from "vue-styled-components";
import { defaultTheme } from "../../shared/theme";
import { themeResolver } from "../../utils";

const themeColor = defaultTheme.themes[themeResolver()].primary;

export const BackArrowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
`;

export const BackArrowTitle = styled.h3`
  color: ${themeColor};
`;
