<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Configurações Gerais do Bp-e
    </v-subheader>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Configurações Gerais do Bp-e</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="primary--text">Série</span>
              <v-text-field
                v-model="dados.serie"
                v-mask-number
                disabled
                placeholder="Informe a série"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tipo de Ambiente *</span>
              <v-select
                v-model="dados.tipo_ambiente"
                :rules="fieldRules"
                :items="items"
                item-value="cod"
                item-text="descricao"
                required
                placeholder="Informe o tipo de ambiente"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Modelo *</span>
              <v-text-field
                placeholder="Informe o modelo"
                class="mt-n1"
                v-model="dados.modelo"
                :rules="fieldRules"
                v-mask-number
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Modalidade *</span>
              <v-select
                placeholder="Informe a modalidade"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.modalidade"
                :rules="fieldRules"
                :items="[
                  { cod: '1', descricao: 'RODOVIÁRIO' },
                  { cod: '3', descricao: 'AQUAVIÁRIO' },
                  { cod: '4', descricao: 'FERROVIÁRIO' },
                ]"
                item-value="cod"
                item-text="descricao"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Versão do Aplicativo</span>
              <v-text-field
                placeholder="Informe a versão do aplicativo"
                class="mt-n1"
                v-model="dados.versao_app"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Versão BPe</span>
              <v-text-field
                placeholder="Informe a versão BPe"
                class="mt-n1"
                v-model="dados.versao_bpe"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Simples Nacional</span>
              <v-select
                placeholder="Informe o simples nacional"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.indSN"
                :rules="fieldRules"
                :items="[
                  { cod: '0', descricao: 'NÃO' },
                  { cod: '1', descricao: 'SIM' },
                ]"
                item-value="cod"
                item-text="descricao"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tipo de Emissão *</span>
              <v-select
                placeholder="Informe o tipo de emissão"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.tipo_emissao"
                :rules="fieldRules"
                :items="[
                  { cod: '1', descricao: 'NORMAL' },
                  { cod: '2', descricao: 'CONTIGÊNCIA OFFLINE' },
                ]"
                item-value="cod"
                item-text="descricao"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Sequência BPe PB</span>
              <v-text-field
                placeholder="Informe a sequência BPe PB"
                class="mt-n1"
                disabled
                :value="dados.codigoBPePB ? dados.codigoBPePB : 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Sequência BPe PE</span>
              <v-text-field
                placeholder="Informe a sequência BPe PE"
                class="mt-n1"
                :value="dados.codigoBPePE ? dados.codigoBPePE : 0"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        serie: "",
        tipo_ambiente: "",
        modelo: "",
        modalidade: "",
        versao_app: "",
        tipo_emissao: "",
        versao_bpe: "1.0.0",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      items: [
        { cod: "1", descricao: "PRODUÇÃO" },
        { cod: "2", descricao: "HOMOLOGAÇÃO" },
      ],
    };
  },
  created() {
    this.$store.dispatch("activeLoader", true);
    this.$http
      .get(`/configbpe`)
      .then((r) => {
        this.dados = r.data[0];
        this.$store.dispatch("activeLoader", false);
      })
      .catch((e) => {
        this.$store.dispatch("activeLoader", false);
        this.message.text = e;
        this.message.snacshow = true;
        this.message.type = "success";
      });
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/configbpe/update", this.dados)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.success) {
            this.message.text = r.data.success;
            this.message.snacshow = true;
            this.message.type = "success";
            setTimeout(() => {
              this.$router.push("/parametros/bpe");
            }, 1500);
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
  },
  components: { BackArrow },
};
</script>

<style src="./style.vue"></style>
