<template>
  <BackArrowContainer @click="$router.back()">
    <v-icon color="primary">mdi-arrow-left</v-icon>
    <BackArrowTitle class="title" style="color: #2054af">Voltar</BackArrowTitle>
  </BackArrowContainer>
</template>

<script>
import { BackArrowContainer, BackArrowTitle } from "./styled";
export default {
  components: { BackArrowContainer, BackArrowTitle },
};
</script>
